//script file will be icluded in HEAD

function getHomepageSearchInputWidth() {
    var hW = $('.header__content').width();
    var lngsW = $('.header .header__content > .langswitcher').width();
    var logoW = $('.header .menu .item--icon').width();
    var searchboxW = $('.header .search a').width();
    var inputW = hW - lngsW - logoW - searchboxW;
    return inputW;
}

function getInnerpageSearchInputWidth() {
    var hW = $('.header__content').width();
    var lngsW = $('.header .header__content > .langswitcher').width();
    var logoW = $('.header .menu .item--logo').width();
    var searchboxW = $('.header .search a').width();
    var inputW = hW - lngsW - logoW - searchboxW;
    return inputW;
}


$('.homepage .header .search a').on('click', function() {
    $(this).siblings('input').css({'opacity': '1'}).width(getHomepageSearchInputWidth() - 30);
    $(this).parents('.header__content').addClass('notprocessmenuhover');
    $(this).parent('.search').css('border', '0');
    return false;
});
$('.inner .header .search a').on('click', function() {
    $(this).siblings('input').css({'opacity': '1'}).width(getInnerpageSearchInputWidth() - 30);
    $(this).parents('.header__content').addClass('notprocessmenuhover');
    $(this).parent('.search').css('border', '0');
    return false;
});

$('*[data-hoverimage-normal]').each(function() {
    var _this = $(this);
    var normalImageUrl = _this.data('hoverimage-normal');
    var hoverImageUrl = _this.data('hoverimage-hover');
    _this.css('background-image', 'url(' + normalImageUrl + ')');
    new Image(hoverImageUrl);

    _this.parent().on('mouseover', function() {
        _this.css('background-image', 'url(' + hoverImageUrl + ')');
    }).on('mouseout', function() {
        _this.css('background-image', 'url(' + normalImageUrl + ')');
    });
});

$('.js-select-trigger').on('click', function (e) {
    if ($(e.target).hasClass('js-search-doctor-input') && $(this).hasClass('open')) { return; }
    $(this).toggleClass('open');
    $(this).siblings('.js-select-results').stop().slideToggle();
    var thisTrigger = $(this);
    var thisResults = $(this).siblings('.js-select-results');
    $('.js-select-trigger').not(thisTrigger).removeClass('open');
    $('.js-select-results').not(thisResults).stop().slideUp();
    $(".js-select-trigger").find('input').focus();
    return false;
});

$('.burger a').on('click', function() {
    if(!$(this).hasClass('triggered')) {
        $(this).addClass('triggered');
        $(this).parents('.header').addClass('menu-opened');
        $(this).parents('.burger').siblings('.menu-responsive-wrap').stop().slideDown();
        $('body').addClass('noscroll');
    } else {
        $(this).removeClass('triggered');
        $(this).parents('.header').removeClass('menu-opened');
        $(this).parents('.burger').siblings('.menu-responsive-wrap').stop().slideUp();
        $('body').removeClass('noscroll');
    }
    return false;
});

$('.card__clinic .text .title').equalHeights();
$('.card__clinic .text .address').equalHeights();

